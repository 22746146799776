.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce #respond input#submit.alt,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt {
  @extend .btn;
  @extend .btn-primary;
  font-size: 15px;

  &:hover {
    background-color: darken($brand-primary, 5%);
  }
}

.woocommerce button.button.alt.disabled {
  background-color: $brand-primary;
  color: #fff;

  &:hover {
    background-color: darken($brand-primary, 5%);
  }
}

.woocommerce,
.woocommerce-page {

  ul.products {
    @include make-row();

    li {

      &.product {
        @include make-xs-column(12);
        margin: 0 0 30px 0;
        
        @media (min-width: 430px) {
          @include make-xs-column(6);
          @include make-md-column(4);
        }
      }

      &.first {
        clear: none;
      }

      .inner {
        padding: 5px;
        background: #fff;

        .button {
          margin-top: 4px;
          display: block;
        }

        a {
          img {
            margin-bottom: 6px;
          }
        }

        .price {
          color: $gray-dark;
        }

        .woocommerce-loop-product__title {
          padding-bottom: 0;
        }
      }
    }
  }

  .related.products {
    clear: both;

    ul.products {
      li.product {
        @include make-sm-column(4);
        @include make-md-column(3);
      }
    }
  }

  div.product {
    
    p.price,
    span.price {
      color: $brand-primary;
    }

    div.summary {
      padding: 20px;
      background: #fff;
    }
    
    form.cart {
      margin-bottom: 1em;
      
      .variations {

        label {
          font-size: 16px;
          line-height: 30px;
          color: $gray-dark;
        }
      }

      .woocommerce-variation-price {
        margin-bottom: 10px;
      }

      .quantity {

        .qty {
          height: 41px;
        }
      }
    }

    .product_meta {

      .sku_wrapper {
        display: none;
      }
    }

    .woocommerce-tabs {

      ul.tabs {
        margin-bottom: 0;
      }

      .panel {
        padding: 0 20px;
        border: 1px solid #d3ced2;
        border-top-color: #fff;
        box-shadow: none;
        border-radius: 0 0 4px 4px;
      }
    }
  }
}
.banner {
  padding: 30px 0;
  margin-bottom: 50px;
  background: #fff;
  position: relative;

  .brand {
    display: block;

    img {
      display: block;
    }
  }

  .menu-toggle {

    .btn {
      text-transform: uppercase;
      line-height: 1em;

      i {
        margin-left: 5px;
      }
    }
  }

  .nav-primary {
    clear: both;
    display: none;
    margin: 0;
    padding: 30px 0 0 0;
    text-align: center;

    @media (min-width: $screen-md-min) {
      clear: none;
      display: block;
      text-align: right;
      padding-top: 20px;
    }

    li {
      border-bottom: 1px solid #f0f0f0;
      position: relative;

      &:hover {
        background: none;
      }

      .fa {
        position: absolute;
        right: 15px;
        top: 15px;
        color: $brand-primary;
      }
    
      @media (min-width: $screen-md-min) {
        display: inline-block;
        border-bottom: none;
        margin: 0 6px;

        .fa {
          display: none;
        }
      }

      @media (min-width: $screen-lg-min) {
        margin: 0 10px;
      }
      
      a {
        font-family: $font-family-brand;
        color: $gray-darker;
        text-transform: lowercase;

        @media (min-width: $screen-md-min) {
          padding: 1px;

          &:hover, &:focus, &:active {
            border-bottom: 3px solid $gray-dark;
            background: none;
          }
        }
      }

      &.active {
        > a {
          border-bottom: 3px solid $brand-primary;
        }
      }

      &.menu-item-has-children {

        &:hover {
          > .sub-menu {
            display: block;
          }
        }
      }

      //submenu
      .sub-menu {
        display: none;
        list-style: none;
        padding: 0;
        margin-bottom: 10px;
        z-index: 10;

        @media (min-width: $screen-md-min) {
          position: absolute;
          top: 26px;
          left: 0;
          background: #fff;
          text-align: left;
          min-width: 100%;
          padding: 3px 0;
          border-bottom: 3px solid $brand-primary;

          &:hover {
            display: block;
          }
        }

        .menu-item {
          display: block;

          a {
            padding: 7px 5px;
            font-size: 0.85em;
            display: block;

            &:hover {
              border-bottom: none;
              color: $brand-primary;
              text-decoration: none;
            }

            @media (min-width: $screen-md-min) {
              padding: 3px 0;
            }
          }
          
          &.active {
            a {
              border-bottom: none;
              color: $brand-primary;
            }
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}
.categorie-blok {
  margin-bottom: 30px;

  a {
    display: block;
    background: #fff;
    padding: 5px;
    position: relative;

    * {
      transition: all 0.3s;
    }
    
    img {
      display: block;
      -webkit-backface-visibility: hidden;
    }
    
    .title {
      position: absolute;
      background: rgba(45,45,45,0.7);
      padding: 8px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      text-align: center;
      color: #fff;
      font-family: 'Secular One', sans-serif;
    }

    &:hover {

      img {
        opacity: 0.9;
      }

      .title {
        background: rgba(45,45,45,1);
      }
    }
  }
}

.row.carousel {
  padding-top: 30px;

  .slider-wrap {
    overflow: hidden;

    .carousel-inner {
      overflow: visible;

      .item {
        height: 0;
        padding-bottom: 75%;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%,-50%,0);
          max-height: 100%;
          max-width: 100%;
        }

        .carousel-caption {
          left: 10%;
          right: 10%;
          bottom: -53px;
          padding: 0;
          
          h3 {
            margin: 0;
            text-shadow: none;
            font-size: $font-size-h4;

            @media (min-width: $screen-md-min) {
              font-size: $font-size-h3;
            }
          }
        }
      }
    }

    .slider-controls {
      padding: 20px 0 0 0;

      a {
        display: block;
        font-size: 1.2em;

        &:first-child {
          padding: 10px 30px 0 0;
        }

        &:last-child {
          padding: 10px 0 0 30px;
        }
      }
    }
  }
}

.carousel-thumbs {

  .carousel-thumb {
    margin-bottom: 30px;
    
    .image-wrap {
      padding: 5px;
      background: #fff;
    }
    
    &.active {
      
      .image-wrap {
        background: $brand-primary;
      }
    }
  }
}
.content-box {
  padding: 26px 32px;
  box-sizing: border-box;
  background: #fff;
}

.eqimage {
  background-size: cover;
  background-position: center center;
  max-height: 700px;
}

.woo-highlight {

  .eq {
    display: block;
    position: relative;
    padding-top: 100%;
    margin-top: 30px;

    @media (min-width: $screen-sm-min) {
      padding-top: 0;
      margin-top: 0;
    }

    &:hover {
      text-decoration: none;

      .button {
        background: rgba($brand-primary, 0.9);
      }
    }

    .button {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 20px;
      background: rgba($brand-primary, 0.7);
      font-size: 1.5em;
      line-height: 1em;
      font-family: $font-family-brand;
      color: #fff;
      text-align: center;
    }
  }
}
// Glyphicons font path
$icon-font-path:        "../fonts/";

// Colors
$brand-primary:         #107EAB;

$brand-success:         #5cb85c;
$brand-info:            #5bc0de;
$brand-warning:         #f0ad4e;
$brand-danger:          #d9534f;

$gray-base:             #000;
$gray-darker:           lighten($gray-base, 20%);
$gray-dark:             lighten($gray-base, 29%);

$body-bg:               #F0F0F0;

$font-family-sans-serif:  'Source Sans Pro', sans-serif;
$font-family-brand:       'Secular One', sans-serif;

$font-size-base:          18px;

$font-size-h1:            floor(($font-size-base * 1.777777778));
$font-size-h2:            ceil(($font-size-base * 1.333333333));
$font-size-h3:            ceil(($font-size-base * 1.2));
$font-size-h4:            ceil(($font-size-base * 1.111111111));
$font-size-h5:            $font-size-base;
$font-size-h6:            ceil(($font-size-base * 0.85));

$line-height-base:        1.333333333;
$line-height-computed:    floor(($font-size-base * $line-height-base));

$headings-font-family:    $font-family-brand;
$headings-font-weight:    400;
$headings-color:          $gray-darker;

$padding-base-vertical:     10px;
$padding-base-horizontal:   20px;

$padding-large-vertical:    14px;
$padding-large-horizontal:  28px;

$padding-small-vertical:    8px;
$padding-small-horizontal:  16px;

$padding-xs-vertical:       2px;
$padding-xs-horizontal:     4px;

$border-radius-base:        5px;
$border-radius-large:       7px;
$border-radius-small:       3px;

$btn-default-color:              $brand-primary;
$btn-default-bg:                 transparent;
$btn-default-border:             $brand-primary;

$btn-primary-border:             $brand-primary;
$btn-success-border:             $brand-success;
$btn-info-border:                $brand-info;
$btn-warning-border:             $brand-warning;
$btn-danger-border:              $brand-danger;
.footer {
  margin-top: 30px;
  padding: 20px 0;
  background: #2C2D2D;
  color: #fff;
  font-size: $font-size-small;
  
  a {
    color: #fff;
  }

  .fa {
    width: 1.2em;
    text-align: center;
  }
  
  h4 {
    color: #fff;
  }

  .menu {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding-left: 0.8em;
      position: relative;

      &:before {
        content: "\f105";
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        left: 0;
        top: 2px;
      }

      &:hover {

        &:before {
          left: 2px;
        }

        a {
          text-decoration: none;
        }
      }
    }
  }
}
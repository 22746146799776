img {
  max-width: 100%;
  height: auto;
}

.btn {
  font-family: 'Secular One', sans-serif;
  font-weight: 400;

  &.btn-default {
    border-width: 2px;
    padding: 9px 19px;
    
    &:hover {
      background: $brand-primary;
      color: #fff;
      border-color: $brand-primary;
    }

    &.btn-sm {
      padding: 7px 15px;
    }
  }
}

.page-header {
  margin: 0;
  padding-bottom: 0;
  border-bottom: none;

  h1 {
    margin-top: 0;
  }
}

.section-title {
  padding-top: 3em;
  padding-bottom: 0.5em;

  h2 {
    margin-top: 0;
    font-size: $font-size-h1;
  }
}